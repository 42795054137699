import React, { useCallback,useState } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Menu,Modal,Form,Input,Button, message,Checkbox } from 'antd';
import { useModel } from 'umi';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { loginUrl,getKey,registerUrl} from '@/services/app';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { encrypt } from './util';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [loginModal,setLoginModal]=useState(false)
  const [cuid,setCuid]=useState('')
  const [cname,setCName]=useState('')
  const [cpassWord,setPassWord]=useState('')
  const [form] = Form.useForm();
  const [loginLoading,setLoginLoading]=useState(false)
  const [registerLoading,setRegisterLoading]=useState(false)
  const [isRegister,setIsRegister]=useState(false)

  const register=()=>{
      setRegisterLoading(true)
       getKey().then(res => { // 获得base64位转换后的公钥
          const password = `{"userId": '${cuid}',"name": '${cname}',"password": '${cpassWord}'}`;
          const decStr = Buffer.from(res?.data, 'base64').toString('utf8')      // 解码公钥得到utf8的公钥
          console.log('解码后的秘钥+++++', decStr)
          const paramEncrypt = encrypt(decStr, password)
          console.log('加密结构++++++', paramEncrypt)
          console.log('加密后的数据记得再base一下才能生效——————————————————————')
          registerUrl({ data: Buffer.from(paramEncrypt).toString('base64') })
          .then((res1: any) => {
            if(res1.code==0){
              setLoginModal(false)
              setRegisterLoading(false)
              setIsRegister(false)
              form.resetFields()
              message.success('注册成功,请点击右上角进行登录')
            }
          })
        }).catch(err => {
          setRegisterLoading(false)
          console.log('password error', err)
        })
  }
  const onMenuClick = useCallback(
    async (event: MenuInfo) => {
      const { key } = event;
      console.log('key',key)
      
      if (key === 'changecurrentUser') {
        setLoginModal(true)
        return;
      }
    },
    [setInitialState],
  );

  const onFinish=(value: any)=>{
    setLoginLoading(true)
    getKey().then(res => { // 获得base64位转换后的公钥
      const password = `{"userId": '${value.currentUserId}',"password": '${value.password}'}`;
      const decStr = Buffer.from(res?.data, 'base64').toString('utf8')      // 解码公钥得到utf8的公钥
      console.log('解码后的秘钥+++++', decStr)
      const paramEncrypt = encrypt(decStr, password)
      console.log('加密结构++++++', paramEncrypt)
      console.log('加密后的数据记得再base一下才能生效——————————————————————')
      loginUrl({ data: Buffer.from(paramEncrypt).toString('base64') })
      .then((res1: any) => {
        if(res1.code==0){
          setInitialState(res1)
          setLoginModal(false)
          setLoginLoading(false)
          message.success('登录成功')
          window.location.reload()
        }
      },()=>{setLoginLoading(false)})
    }).catch(err => {
      setLoginLoading(false)
      console.log('password error', err)
    })
  }

  const loading = ()=>{
    return (
      <span onClick={()=>{
        setLoginModal(true)
      }} className={`${styles.action} ${styles.account}`}>
        请登录
      </span>
    )
  }
   
  const { currentUser } = initialState || {};

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="changecurrentUser">
        <LogoutOutlined />
        切换账号
      </Menu.Item>
    </Menu>
  );
  return (
    <>
     {
            !currentUser?.userId?loading():<HeaderDropdown overlay={menuHeaderDropdown}>
            <span className={`${styles.action} ${styles.account}`}>
              <span className={`${styles.name} anticon`}>
                {
                  !currentUser?.userId?loading():currentUser?.userName
                }
              </span>
            </span>
          </HeaderDropdown>
      }
      <Modal title="请登录" open={loginModal} onOk={()=>{setLoginModal(false);setIsRegister(false);form.resetFields()}} onCancel={()=>{setLoginModal(false);setIsRegister(false);form.resetFields()}} footer={null}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 15 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
       <Form.Item
          label="账号"
          name="currentUserId"
          rules={[{ required: true, message: '请输入账号' }]}
        >
          <Input onChange={(e)=>{setCuid(e.target.value)}} />
        </Form.Item>

        {
          isRegister?<Form.Item
          label="昵称"
          name="currentUsername"
          rules={[{ required: true, message: '请输入昵称' }]}
        >
          <Input onChange={(e)=>{setCName(e.target.value)}}  />
        </Form.Item>:''
        }

        <Form.Item
          label="密码"
          name="password"
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password  onChange={(e)=>{setPassWord(e.target.value)}} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          {
            isRegister?<Button loading={registerLoading} type="primary" onClick={()=>{register()}}>
            注册</Button>:<Button  type="primary" onClick={()=>{setIsRegister(true)}}>
            去注册</Button>
          }
          {
            isRegister? <Button  type="primary" onClick={()=>{console.log(2312312);setIsRegister(false)}} style={{marginLeft:'25px'}}>
            去登录</Button>:<Button loading={loginLoading} type="primary" htmlType="submit" style={{marginLeft:'25px'}}>
            登录</Button>
          }
         
        </Form.Item>
      </Form>
      </Modal>
    </>
  );
};

export default AvatarDropdown;
