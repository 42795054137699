/* eslint-disable newline-per-chained-call */
/* eslint-disable max-len */
// import JSEncrypt from 'jsencrypt/bin/jsencrypt.js'
import NodeRSA from 'node-rsa'
import jsrsasign from 'jsrsasign'

export const getMobileVal = ({ areaCode, mobile }) => {
  if (!areaCode || !mobile) {
    return null
  }
  if (areaCode === '86') {
    return mobile
  }
  return `+${areaCode}-${mobile}`
}

export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAknG1U8YbjJWRijdHUKjm
l/IQbZ3Z1gIGXyXWNVEUTIexd0ddkFQ6iwPn+aN+GqgTSqCkekMPbsAChybEmf45
CYV0Qy+6OskoALnjOR58+4xUO6OYvUz9pTfUxbk8DEFrGrllztNhnyM1PUNN/tp5
n03t+Y45sZlD0FPb+4IFKmvzuMSG/OlD0w/Kcj6GXW4mIiVsAtb2Vd0mPm+aMVWq
F6dOG9f0SPu4L3erO05Iijyp1DHuParctWlGEa43RCPFhUdFqlws+ftuf5PzUbsX
KvD5TkrlAJQdsTPysJpGzDqoDOcGSnvm5YAzoKqj0t0EPA3pyzVzbW/zg+c8kVbY
JQIDAQAB
-----END PUBLIC KEY-----`

export const testPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzZYKwi6hLW5kJKe8o2/u
DQMCIwgEiasht+l29+dFow2wPShlW7ga3v6ZaKcbaNmBVdm+Q5micZ9hOUyjwWZH
cBwQTss4/OQGMeImWbEjXTg5Q+i9WugkrQcRxFsiB4nPj3ZOb+kug6n5G4g5K82N
pVvw+RZyYQiHovy0EXVaB8aV7YXLioAu9H96dmf+Bq2FYBLfSgCB6NOufqHUkkqk
btrGP0R4hBfPMbDQC5mT7zH78TXIKTjpKJPrZElIRYJ6sC3hgZPyRaipYL53qxsn
iIigI/EfD7iNmChFYAmme8PZO+mDXHsXhkvKKn+RQEUVr5D4IdHG88p++3622jSR
BQIDAQAB
-----END PUBLIC KEY-----`

export const testPrivateKey = `-----BEGIN PRIVATE KEY-----
MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDXOnqFRggP/NR2
Fo05VW1G4eYy9JsYuc9Kt95aD4zs9AQLcaLs/kCpDp0TtJ6iYhJwbSjin+I3OJlN
EbNOThE8BsZyyz4UA8EwrYROLMYYLdtpu2sUW3w+Pq6Ycb8D/1yhdoAQH/JR96DP
vOUQOQOMtkj2d6gF66t9l4mblUjpy+E3CJ37yTh6mJY/aoG7j+f6tID/zgMRJBny
e1sIY5lrMY+sK+aX3qAbIAij/VNGxB0Gfa9L8xpfikX5QhxVzzOshKJNvFw9E+14
KqID5cdG4G9jox5YIrAGO3azG41LKrTo7nm1sytVAtBFjAvca+5lUk21TvVeJXbi
XgaiVLF9AgMBAAECggEBAMsSHwXeRL8o8yGUN51v79IgVD2hszDKKD68a2wYHgR4
W9ScXHg1kLax1h0An2hhFjrDk/73kc4+dc5UtBRe7j3vupAXGbRysnYGUb4c2q93
nP0O24JyqzBjngKx1+OirjieAUi1i8Iw3acD+DCtSp8aYo9B6OPlL4IpV6nXWdtU
SWTcpdpE/BYoMRseyku+hZpnCDPGwuq8SF5lwTdguCs2YARVocH5bG7ua7mbgtQo
Gnpitw5AUnVGGAZJXJ/1FPCPHdPthag3ic+V2WWIaUCxklFxlNb6bND+e4CJiX6Q
QMEN1BMYQudTeomjl6lmXFUJ+pcprs5gCInhCQsFM9UCgYEA+23NChj2AWEAfhv8
YQycxfgDbFEFHEKDi5mzoOWM1LkUDb/VhgTcD+sde7FUlVu4lDfKtnnZfPMoPGNF
F8m5s9Mio4ySVk9XZCbhua1xzOqRZvPirPi8CrW6psffVRvRJCrf7H8X0U1OWGUW
eNlI93GrQlTMvankcZjoIvGVKkMCgYEA2yQxj1hBDa2dxwmkahexmkqtIPE2R2JY
9BTiAn2/FcIbJIEQ5xYLxe2VXdA0ZXizh+ciBMkcRigsYIRf/or1h8ZSX4UOtXWn
TD40Z/cw989UDPc27x7HH0hiH8OJP8X0GYhUOVchMxLtUvrtE1h2ggGQ4VFQRJzQ
TdM8H6V6WT8CgYEAySf64HZB865452xaiga+MwEPNAzKKuboObs55KNi/hoo+isd
mQCwU6gGyZyBkJF2ZlcQvtdT8szTxySg9bw177IfR3xC4/zMbVtSQuyKXKjateSv
2TWTtHnaoG7OtlfwBqCq0JqKIrFX2g7eYMduwlHV89bGi4OuOlhceQCRF+MCgYEA
hIwnzeQ7tOJGhJU4dVNPgVuZzEsZ4aOC2l+nNIqKgcaeVD4c0ylqhZVmuw+sIZQY
QyNyWCKsj2LNNLPcvEGgsTcrq8r3UpqPTnZxjFDftYQZ+p2tBIVBO5K0hzHmWkSJ
mMf06Znh4tqX4ZEUxJbFV21LDH36ZwXSmMAjpaCZmFECgYBWBV6NQ4VSD/0wSzJ0
hR5FoBGphIJLWG2Vq7QGncaMxay/5rz3ucM6OkHbdQW55At2lrgwOFCjKF+/DKhh
gHDOpl8u76WW0/1X0lp8sZ6AhoM+XsNrqvw2ocMqLRt2WXzc4EtcvBXb5GcEH1pG
420bh/CM4X3+bU3kV8FuaJYU2g==
-----END PRIVATE KEY-----`

// 加密
export const encrypt1 = (pbk, params) => {
  console.log('jsrsasign pbk___________:', pbk);
  // 生成公钥实例
  const pub = jsrsasign.KEYUTIL.getKey(pbk);
  console.log('jsrsasign pubkey___________:', pub);
  const enc = jsrsasign.KJUR.crypto.Cipher.encrypt(params, pub);
  console.log('jsrsasign decrypt____________:', enc);
  console.log('jsrsasign hextob64____________: ', jsrsasign.hextob64u(enc));
  return jsrsasign.hextob64(enc);
}

export const encrypt = (pbk, params) => {
  // const nodersa = new NodeRsa(key);
  // const key = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAknG1U8YbjJWRijdHUKjml/IQbZ3Z1gIGXyXWNVEUTIexd0ddkFQ6iwPn+aN+GqgTSqCkekMPbsAChybEmf45CYV0Qy+6OskoALnjOR58+4xUO6OYvUz9pTfUxbk8DEFrGrllztNhnyM1PUNN/tp5n03t+Y45sZlD0FPb+4IFKmvzuMSG/OlD0w/Kcj6GXW4mIiVsAtb2Vd0mPm+aMVWqF6dOG9f0SPu4L3erO05Iijyp1DHuParctWlGEa43RCPFhUdFqlws+ftuf5PzUbsXKvD5TkrlAJQdsTPysJpGzDqoDOcGSnvm5YAzoKqj0t0EPA3pyzVzbW/zg+c8kVbYJQIDAQAB' // 导出公钥
  // const key = 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1JSUJJakFOQmdrcWhraUc5dzBCQVFFRkFBT0NBUThBTUlJQkNnS0NBUUVBa25HMVU4WWJqSldSaWpkSFVLam0NCmwvSVFiWjNaMWdJR1h5WFdOVkVVVElleGQwZGRrRlE2aXdQbithTitHcWdUU3FDa2VrTVBic0FDaHliRW1mNDUNCkNZVjBReSs2T3Nrb0FMbmpPUjU4KzR4VU82T1l2VXo5cFRmVXhiazhERUZyR3JsbHp0TmhueU0xUFVOTi90cDUNCm4wM3QrWTQ1c1psRDBGUGIrNElGS212enVNU0cvT2xEMHcvS2NqNkdYVzRtSWlWc0F0YjJWZDBtUG0rYU1WV3ENCkY2ZE9HOWYwU1B1NEwzZXJPMDVJaWp5cDFESHVQYXJjdFdsR0VhNDNSQ1BGaFVkRnFsd3MrZnR1ZjVQelVic1gNCkt2RDVUa3JsQUpRZHNUUHlzSnBHekRxb0RPY0dTbnZtNVlBem9LcWowdDBFUEEzcHl6VnpiVy96ZytjOGtWYlkNCkpRSURBUUFCDQotLS0tLUVORCBQVUJMSUMgS0VZLS0tLS0='
  const encryptor = new NodeRSA(pbk)  // 创建加密对象实例
  // encryptor.importKey(key, 'pkcs8-public')
  // const encryptorPri = new NodeRSA(privateKey, 'pkcs8-private')
  encryptor.setOptions({ encryptionScheme: 'pkcs1' });
  // encryptorPri.setOptions({ encryptionScheme: 'pkcs1' });
  // const encrypted = encryptor.encrypt(Buffer.from(params, 'utf8').toString('base64'), 'base64', 'utf8')
  const encrypted = encryptor.encrypt(params, 'base64')
  console.log('加密结果+++++', Buffer.from(params, 'utf8').toString('base64'))
  console.log('解密结果+++++', encrypted)
  return encrypted;  // 对内容进行加密
}

