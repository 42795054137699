// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/linxiaowei/Desktop/itool/iTool/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/Users/linxiaowei/Desktop/itool/iTool/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/index.html",
        "redirect": "/home",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/home",
        "exact": true
      },
      {
        "path": "/home",
        "icon": "SmileOutlined",
        "name": "首页",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'/Users/linxiaowei/Desktop/itool/iTool/src/pages/Home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/myApp",
        "icon": "SmileOutlined",
        "name": "我的应用",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyApp' */'/Users/linxiaowei/Desktop/itool/iTool/src/pages/MyApp'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/createApp",
        "icon": "SmileOutlined",
        "name": "应用创建",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CreateApp' */'/Users/linxiaowei/Desktop/itool/iTool/src/pages/CreateApp'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/editApp/:id",
        "icon": "SmileOutlined",
        "name": "编辑应用",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CreateApp' */'/Users/linxiaowei/Desktop/itool/iTool/src/pages/CreateApp'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/useApp/:id",
        "icon": "SmileOutlined",
        "name": "使用应用",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UseApp' */'/Users/linxiaowei/Desktop/itool/iTool/src/pages/UseApp'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/linxiaowei/Desktop/itool/iTool/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
