import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import * as Sentry from '@sentry/react';
import { fetchCurrentUser } from '@/services/user';
import requestConfig from '@/utils/requestConfig';
import { message } from 'antd';

const fetchUserInfo = async () => {
  const res = await fetchCurrentUser();
  if (res.code !== 0) {
    return undefined;
  }
  // sentry中添加用户信息
  Sentry.setExtra('userId', res?.data?.userId);
  Sentry.setExtra('userName', res?.data?.userName);
  return res.data;
};

// 运行时 Request 配置 https://umijs.org/zh-CN/plugins/plugin-request
export const request = requestConfig;

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: LOGIN.CurrentUser;
  fetchUserInfo?: () => Promise<LOGIN.CurrentUser | undefined>;
}> {
  const currentUser = await fetchUserInfo();
  if (!currentUser?.userId) {
    message.error('请登录')
  }

  return {
    currentUser,
    fetchUserInfo,
    settings: {},
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    layout: 'top',
    onPageChange: () => {
      if (!initialState?.currentUser) {
        message.error('用户未登录 请点击右上角登录')
      }
    },
    links: [], // 想给侧边菜单添加点啥连接就写在这
    menuHeaderRender: undefined,
    ...initialState?.settings,
  };
};
