import './utils/initSentry';
import { message, notification } from 'antd';

message.config({
  maxCount: 1,
});

window.addEventListener('offline', () => {
  notification.error({
    message: '网络异常',
    description: '请检查您的网络状态',
  });
});
