import qs from 'query-string';
import type { RuleObject } from 'antd/lib/form';
import { LOGIN_URL_MAP } from './constants';

/**
 * 取 urlPath 的第一个字段
 * getUrlPathDomain('/chess/admin/login') -> 'chess'
 */
export const getUrlPathDomain = (urlPath: string) => {
  return /^\/(\w+)\/\w+/.exec(urlPath)?.[1];
};

/**
 * 过滤数字中的小数点及其他非数字字符, 配合 inputNumber 组件实现只允许输入整数的输入框
 */
export const limitNumber = (input: string = '') => {
  return input.replace(/^(0+)|[^\d]+/g, '');
};

/**
 * 生成 form Input 过滤首尾空格后非空输入的 validator
 * @param errMsg 为空时的提示信息
 */
export const genFormStringNoEmptyValidator = (errMsg = '输入不能为空') => {
  return (_: RuleObject, value: any) => {
    const length = value?.trim()?.length;
    if (length > 0) return Promise.resolve();
    return Promise.reject(new Error(errMsg));
  };
};

/**
 * 16进制数字生成
 * @param length
 * @returns
 */
const rand16Num = (length: number) => {
  const chars = '0123456789abcdef';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

/**
 * 生成一个全局唯一的guid，且格式符合guid规范
 * GUID 的格式为“xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx”
 * 其中每个 x 是 0-9 或 a-f 范围内的一个32位十六进制数
 * 第四版的GUID使用了新的算法，其产生的数字是一个伪随机数。
 * 它生成的GUID的第三组数字的第一位是4
 *
 * @return {string} 符合guid格式的字符串
 */
export const guid = () => {
  const curr = new Date().valueOf().toString();
  return ['4b534c47', rand16Num(4), `4${rand16Num(3)}`, rand16Num(4), curr.substring(0, 12)].join(
    '-',
  );
};

/**
 * 判断数组中某个字段是否存在重复的值
 * @param arr
 * @param key
 * @returns
 */
export const isArrHasDuplicateType = <T extends Record<string, any>>(
  arr: T[],
  key: keyof T,
): boolean => {
  return arr.some((item, index) => {
    return arr.findIndex((el) => el[key] === item[key]) !== index;
  });
};

/**
 * 判断数组中某个字段是否存在空值
 * @param arr
 * @param key
 * @returns
 */
export const isArrHasNullItem = <T extends Record<string, any>>(
  arr: T[],
  key: keyof T,
): boolean => {
  return arr.some((item) => {
    const value = item[key];
    return value === null || value === undefined || value === '';
  });
};

/**
 * 将返回的字符串 'data: ChatChunk序列化字符串' 反序列化
 * @param str sse返回的dataString
 */
export const parseStreamChunk = (str: string) => {
  const res: any[] = [];
  const val = new String(str);
  val.split(/\n|\r\n|\r/).forEach((curr) => {
    if (curr.trim() !== '') {
      const json = curr?.trimLeft().replace(/^data:/, '') || '{}';
      try {
        const data = JSON.parse(json);
        res.push(data);
      } catch (error) {
        console.log('parseStreamChunk  error', curr);
      }
    }
  });
  return res;
};

/**
 * 禁止冒泡
 * @param event
 */
export const stopPropagation = (event: Event) => {
  event = event || window.event;
  if (event.stopPropagation) {
    event.stopPropagation();
  } else {
    event.cancelBubble = true;
  }
};

/**
 * 获取url的query参数键值对
 */
export function getUrlSearchValue() {
  const hash = window.location.hash.slice(1); // 去掉哈希值前面的 #
  const queryIndex = hash.indexOf('?'); // 查找查询参数的位置
  const queryParams =
    queryIndex >= 0 ? qs.parse(hash.slice(queryIndex + 1), { parseNumbers: true }) : {}; // 解析查询参数
  return queryParams;
}

/**
 * @desc  函数防抖---“立即执行版本” 和 “非立即执行版本” 的组合版本
 * @param  func 需要执行的函数
 * @param  wait 延迟执行时间（毫秒）
 * @param  immediate---true 表立即执行，false 表非立即执行
 * */
export function debounce(func: Function, wait: number, immediate: boolean) {
  let timer: NodeJS.Timeout | null;
  return function () {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-invalid-this,@typescript-eslint/no-this-alias
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (timer) clearTimeout(timer);
    if (immediate) {
      const callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timer = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    }
  };
}

/**
 * JSON.parse
 * @param {string} str
 * @returns {Object}
 */
export const jsonParse = (str?: string, defaultRet?: any) => {
  let ret = defaultRet || {};
  if (!str) return ret;
  try {
    ret = JSON.parse(str);
  } catch (e) {
    console.log(e);
  }
  return ret;
};

/** 移除数组中指定元素 */
export const removeElementInArr = (array: any[], element: any) =>
  array.filter((item) => item !== element);

/** 重新登录 */
// export const Login = () =>
//   (window.location.href = `${LOGIN_URL_MAP}${window.location.href.split('#')[0]}`);

/**
 * 计算字符数
 * @param str
 * @returns
 */
export const calculateLength = (str: string): number => {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    if (code >= 0 && code <= 128) {
      // 英文、数字、标点符号
      len += 1;
    } else {
      // 中文和其他非 ASCII 字符
      len += 2;
    }
  }
  return len;
};

/**
 * gpt模板数据整合变量
 * @param prompt 模板
 * @param variablesObj 变量
 * @returns
 */
export const formatTemplate = (
  prompt: string,
  variablesObj: Record<string, APP.VariablesStruct>,
) => {
  if (!prompt) return '';
  // 使用正则表达式匹配出 $变量名$ 的模式，并将变量名提取出来
  const variableNames = Object.keys(variablesObj).join('|');
  const regex = new RegExp(`\\$(${variableNames})\\$`, 'g');
  console.log('regex', regex);
  return prompt.replace(regex, (match: any, variableName: any) => {
    if (variablesObj.hasOwnProperty(variableName)) {
      return variablesObj[variableName].value;
    }
    console.log('输出实际模板：', match);
    return match;
  });
};

/**
 * @desc  函数节流---“立即执行版本” 和 “非立即执行版本” 的组合版本
 * @param  func 需要执行的函数
 * @param  wait 延迟执行时间（毫秒）
 * @param  immediate---true 表立即执行，false 表非立即执行
 * */
export function throttle<T extends Function>(
  func: T,
  wait: number = 2000,
  immediate: boolean = false,
) {
  let timer: NodeJS.Timeout | null;
  return function () {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-invalid-this,@typescript-eslint/no-this-alias
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (timer) {
      return;
    }
    if (immediate) {
      const callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timer = setTimeout(function () {
        func.apply(context, args);
        timer = null;
      }, wait);
    }
  } as unknown as T;
}
