import type { RequestConfig } from 'umi';
import { message, notification } from 'antd';
import { Login, getUrlPathDomain } from '@/utils';
import { SEVER_URL_MAP } from '@/utils/constants';

export const serverUrlDomainMap = SEVER_URL_MAP[REACT_APP_ENV || 'prod'];

if (EXEC_ENV === 'local') {
  console.log('serverUrl', serverUrlDomainMap);
}

export default {
  credentials: 'include',
  requestInterceptors: [
    (url, options) => {
      const domain = getUrlPathDomain(url);
      if (!domain) return { url, options };
      const serverUrl = serverUrlDomainMap[domain];

      // 部署到服务器上时 ,使用真实绝对接口地址
      if (EXEC_ENV !== 'local') {
        return {
          url: serverUrl ? serverUrl + url.replace(new RegExp(`^/${domain}`), '') : url,
          options: {
            ...options,
            interceptors: true,
          },
        };
      }

      // 本地开发时使用 localhost 配合 proxy
      return { url, options };
    },
  ],
  errorConfig: {
    adaptor: (resData) => {
      return {
        ...resData,
        success: resData?.err === 0 || resData?.code === 200 || resData?.code === 0, // https://umijs.org/zh-CN/plugins/plugin-request
        errorMessage: resData?.msg || '网络错误',
      };
    },
  },
  errorHandler: (error) => {
    if (error?.data?.code === 40001) {
      // 未登录 则进入登录页面
      message.error('用户未登录 请点击右上角去登录');
    }
    if (error?.data?.msg) {
      // 已预知的错误即  adaptor 中 success 为 false 的情况
      const msg = error?.data?.msg;
      message.error(msg);
      return Promise.reject(msg);
    }
    // 未知错误,即请求出错,如 400 404 500
    notification.error({
      message: error?.data?.error || '请求发生错误',
      description: `url: ${error?.request?.url} \n type: ${error?.type} \n message: ${error?.data?.message}`,
    });
    return Promise.reject(error?.data?.message || '请求发生错误');
  },
} as RequestConfig;
