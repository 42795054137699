/**
 * @file 应用（App）相关请求
 */

import { request } from 'umi';

/**
 * 首页列表
 * @param data
 * @returns
 */
export const fetchAppList = (data?: APP.IGetAppReq): API.fetchResponse<APP.AppCommonStruct[]> =>
  request('/gpt/app/list', {
    method: 'POST',
    data,
  });

/**
 * 获取标签列表
 * @param data
 * @returns
 */
export const getLabelList = (): API.fetchResponse<APP.LabelStruct[]> => request('/gpt/label/list');

export const getKey=() =>
request('http://itool.life/aigc/account/login/public/key/get');

export const loginUrl=(data?: any): API.fetchResponse<APP.AppCommonStruct[]> =>
request('http://itool.life/aigc/account/login/sign/in', {
  method: 'POST',
  credentials: 'include',
  data,
});

export const registerUrl=(data?: any): API.fetchResponse<APP.AppCommonStruct[]> =>
request('http://itool.life/aigc/account/register/user', {
  method: 'POST',
  data,
});
/**
 * 我的应用列表
 * @param data
 * @returns
 */
export const fetchMyAppList = (data: APP.IGetMyAppReq): API.fetchResponse<APP.AppDetailStruct[]> =>
  request('/gpt/user/app/list', {
    method: 'POST',
    data,
  });

/**
 * 模板详情
 * @param data
 * @returns
 */
export const fetchPromptDetail = (
  data: APP.IGetPromptDetailReq,
): API.fetchResponse<APP.AppDetailStruct> =>
  request('/gpt/prompt/detail', {
    method: 'POST',
    data,
  });

/**
 * 获取应用详情
 * @param data
 * @returns
 */
export const fetchAppDetail = (
  data: APP.IGetAppDetailReq,
): API.fetchResponse<APP.AppDetailStruct> =>
  request('/gpt/app/detail', {
    method: 'POST',
    data,
  });

/**
 * 应用收藏 / 取消收藏
 * @param data
 * @returns
 */
export const collectApp = (data: APP.ICollectAppReq): API.fetchResponse<''> =>
  request('/gpt/user/app/collect', {
    method: 'POST',
    data,
  });

/**
 * 应用创建 / 更新
 * @param data
 * @returns
 */
export const saveApp = (data: APP.ISaveAppReq): API.fetchResponse<''> =>
  request('/gpt/app/save', {
    method: 'POST',
    data,
  });

/**
 * 应用草稿创建 / 更新
 * @param data
 * @returns
 */
export const saveAppDraft = (data: APP.ISaveAppReq): API.fetchResponse<''> =>
  request('/gpt/user/draft/save', {
    method: 'POST',
    data,
  });

/**
 * 删除应用
 * @param data
 * @returns
 */
export const deleteApp = (data: APP.IDeleteAppReq): API.fetchResponse<''> =>
  request('/gpt/app/delete', {
    method: 'POST',
    data,
  });

/**
 * 检测应用是否有草稿
 * @param data
 * @returns
 */
export const checkAppHasDraft = (params: APP.IAppHasDraftReq): API.fetchResponse<''> =>
  request('/gpt/user/app/draft', {
    params,
  });
