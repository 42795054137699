// 接口地址
export const SEVER_URL_MAP = {
  dev: {
    gpt: `${window.location.protocol}//itool.life/aigc`,
  },
  prod: {
    gpt: `${window.location.protocol}//itool.life/aigc`,
  },
};

